<template>
  <div class="Tests">
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Tests</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Tests</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-light">
              <div class="card-body">
                <vue-good-table :columns="columns" :rows="tests" :search-options="searchOptions" :pagination-options="paginationOptions">
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'Name'">
                      <router-link :to="{ name: 'TestDetails', params: { uuid: props.row.UUID } }">
                        <b>{{ props.row.Name }}</b>
                      </router-link>
                    </span>
                    <span v-if="props.column.field == 'TotalQuestions'">{{ props.row.TotalQuestions }}</span>
                    <span v-if="props.column.field == 'TimeSet'">{{ props.row.TimeSet }}</span>
                    <span v-if="props.column.field == 'Type'">{{ props.row.Type }}</span>
                    <span v-if="props.column.field == 'CreatedAt'">{{ props.row.CreatedAt | formatDate }}</span>
                    <span v-if="props.column.field == 'Questions'">
                      <router-link :to="{ name: 'Questions', params: { testUUID: props.row.UUID } }">Questions</router-link>
                    </span>
                    <!-- <span v-if="props.column.field == 'Details'">
                      <router-link :to="{ name: 'TestDetails', params: { uuid: props.row.UUID } }">Details</router-link>
                    </span> -->
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "Tests",
  data() {
    return {
      tests: [],
    };
  },

  beforeMount() {
    axios
      .get("/authoring/my-tests")
      .then((res) => {
        this.tests = res.data.tests;
      })
      .catch((error) => {});
  },

  computed: {
    columns() {
      let cols = [
        {
          label: "Name",
          field: "Name",
        },
        {
          label: "Total Questions",
          field: "TotalQuestions",
        },
        {
          label: "Time Set",
          field: "TimeSet",
        },
        {
          label: "Type",
          field: "Type",
        },

        {
          label: "Data/Time",
          field: "CreatedAt",
        },
        {
          label: "",
          field: "Questions",
          sortable: false,
        },
        /* {
          label: "",
          field: "Details",
          sortable: false,
        }, */
      ];
      return cols;
    },

    searchOptions() {
      return {
        enabled: true,
        trigger: "enter",
      };
    },

    paginationOptions() {
      return {
        enabled: true,
        perPage: 20,
      };
    },
  },

  components: {
    VueGoodTable,
  },
};
</script>
