import axios from 'axios'
import store from '../store' // your vuex store 

let ifAuthenticated = (to, from, next) => {
    let formData = new FormData();
    formData.append("log_uuid", window.localStorage.getItem("log_uuid"));
    axios.post("/auth/refresh", formData).then(res => {
        if (res.data.status == "success" && res.data.user.roleSlug == "author") {
            store.state.authToken = res.data.token
            store.state.authUser = res.data.user
            store.state.loggedIn = true
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
            next();
            return
        }
        else {
            next('/login') //alert(res.data.message)
            return
        }
    })
}

let ifNotAuthenticated = (to, from, next) => {
    if (!store.state.loggedIn) {
        next()
        return
    }
    next("/") // Home is for user that are login
}

export default {
    ifAuthenticated,
    ifNotAuthenticated
} 