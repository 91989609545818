import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../helper/auth'

import Login from '../views/Login.vue'
import PageNotFound from '../views/PageNotFound.vue'

import Home from '../views/Home.vue'

//import Home from '../views/Home.vue'
import Courses from '../views/course/Courses.vue'
import CourseEdit from '../views/course/CourseEdit.vue'
import CourseCreate from '../views/course/CourseCreate.vue'
import CourseDetails from '../views/course/CourseDetails.vue'

// Testing
import Tests from '../views/test/Tests.vue'
import TestEdit from '../views/test/TestEdit.vue'
import TestCreate from '../views/test/TestCreate.vue'
import TestDetails from '../views/test/TestDetails.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: auth.ifAuthenticated,
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: auth.ifNotAuthenticated,
  },

  /* Courses */
  // Courses
  {
    path: '/courses',
    name: 'Courses',
    component: Courses,
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/courses/create',
    name: 'CourseCreate',
    component: CourseCreate, // () => import("../views/course/CourseCreate.vue"), //
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/courses/:uuid',
    name: 'CourseDetails',
    component: CourseDetails, // () => import("../views/course/CourseDetails.vue"), // 
    beforeEnter: auth.ifAuthenticated,
  },

  {
    path: '/courses/edit/:uuid',
    name: 'CourseEdit',
    component: CourseEdit,// () => import("../views/course/CourseEdit.vue"),  // 
    beforeEnter: auth.ifAuthenticated,
  },

  // Lessons
  {
    path: '/lessons/:courseUUID',
    name: 'Lessons',
    beforeEnter: auth.ifAuthenticated,
    component: () => import("../views/lesson/Lessons.vue"),
  },


  /* *********************************************************** */
  // Tests
  /* *********************************************************** */
  {
    path: '/tests',
    name: 'Tests',
    component: Tests,
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/tests/create',
    name: 'TestCreate',
    component: TestCreate, // () => import("../views/course/CourseCreate.vue"), //
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/tests/:uuid',
    name: 'TestDetails',
    component: TestDetails, // () => import("../views/course/CourseDetails.vue"), // 
    beforeEnter: auth.ifAuthenticated,
  },

  {
    path: '/tests/edit/:uuid',
    name: 'TestEdit',
    component: TestEdit,// () => import("../views/course/CourseEdit.vue"),  // 
    beforeEnter: auth.ifAuthenticated,
  },
  // Questions
  {
    path: '/questions/:testUUID',
    name: 'Questions',
    beforeEnter: auth.ifAuthenticated,
    component: () => import("../views/questions/Questions.vue"),
  },

  /*   {
      path: '/lessons/show/:lessonUUID',
      name: 'LessonDetails',
      beforeEnter: auth.ifAuthenticated,
      component: () => import("../views/lesson/LessonDetails.vue"),
    },
  
    {
      path: '/lessons/questions/:lessonUUID',
      name: 'Questions',
      beforeEnter: auth.ifAuthenticated,
      component: () => import("../views/lesson/question/Questions.vue"),
    }, */


  /* Error Page */
  {
    path: '/*',
    name: 'PageNotFound',
    component: PageNotFound
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
