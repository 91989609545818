<template>
  <div class="CourseCreate">
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Create Course</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/courses">Courses</router-link>
              </li>
              <li class="breadcrumb-item active">Create Course</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-light">
              <div class="card-header">
                <h3 class="card-title">Create Course Account</h3>
              </div>
              <div class="card-body">
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">
                          Name
                          <span class="text-danger">*</span>
                        </label>
                        <input v-model="course.name" type="text" class="form-control" :class="{ 'is-invalid': $v.course.name.$error, 'is-valid': !$v.course.name.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.name.required">Name is required.</span>
                          <span v-if="!$v.course.name.minLength">Name must be at least {{ $v.course.name.$params.minLength.min }} letters.</span>
                          <span v-if="!$v.course.name.maxLength">Name must be at most {{ $v.course.name.$params.maxLength.max }} letters.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="introduction">
                          Introduction
                          <span class="text-danger">*</span>
                        </label>
                        <textarea v-model="course.introduction" class="form-control" :class="{ 'is-invalid': $v.course.introduction.$error, 'is-valid': !$v.course.introduction.$invalid }"></textarea>
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.introduction.required">Introduction is required.</span>
                          <span v-if="!$v.course.introduction.minLength">Introduction must be at least {{ $v.course.introduction.$params.minLength.min }} letters.</span>
                          <span v-if="!$v.course.introduction.maxLength">Introduction must be at most {{ $v.course.introduction.$params.maxLength.max }} letters.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="prerequisite">
                          Prerequisite
                          <span class="text-danger">*</span>
                        </label>
                        <vue-editor v-model="course.prerequisite" :editorToolbar="this.$store.state.editorToolbar" :class="{ 'is-invalid': $v.course.prerequisite.$error, 'is-valid': !$v.course.prerequisite.$invalid }"></vue-editor>
                        <input v-model="course.prerequisite" type="hidden" class="form-control" :class="{ 'is-invalid': $v.course.prerequisite.$error, 'is-valid': !$v.course.prerequisite.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.prerequisite.required">Prerequisite is required.</span>
                          <span v-if="!$v.course.prerequisite.minLength">Prerequisite must be at least {{ $v.course.prerequisite.$params.minLength.min }} letters.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="description">
                          Description
                          <span class="text-danger">*</span>
                        </label>
                        <vue-editor v-model="course.description" :editorToolbar="this.$store.state.editorToolbar" :class="{ 'is-invalid': $v.course.description.$error, 'is-valid': !$v.course.description.$invalid }"></vue-editor>
                        <input v-model="course.description" type="hidden" class="form-control" placeholder="e.g. Mark" :class="{ 'is-invalid': $v.course.description.$error, 'is-valid': !$v.course.description.$invalid }" />
                        <div class="invalid-feedback">
                          <span v-if="!$v.course.description.required">Description is required.</span>
                          <span v-if="!$v.course.description.minLength">Description must be at least {{ $v.course.description.$params.minLength.min }} letters.</span>
                        </div>
                      </div>
                      <!-- end col-md-12 -->
                    </div>
                  </div>

                  <button :disabled="isDisabled" class="btn btn-danger" type="submit" id="submitButton">
                    <i class="far fa-paper-plane"></i>
                    Create
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import { required, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "CourseCreate",
  data() {
    return {
      course: {
        name: "",
        introduction: "",
        prerequisite: "",
        description: "",
      },
      submitted: false,
      isDisabled: false,
    };
  },
  validations: {
    course: {
      name: { required, minLength: minLength(5), maxLength: maxLength(150) },
      introduction: { required, minLength: minLength(25), maxLength: maxLength(300) },
      prerequisite: { required, minLength: minLength(25) },
      description: { required, minLength: minLength(25) },
    },
  },
  components: {
    VueEditor,
  },
  created() {
    document.title = "Create Course - Coregrade LMS";
    this.$store.commit("SET_LAYOUT", "admin-layout");
  },
  mounted() {
    //this.$store.dispatch("Courses");
  },
  computed: {
    s3() {
      return this.$store.state.s3;
    },
  },
  methods: {
    handleSubmit(e) {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isDisabled = false;
        return;
      }

      this.submitted = true;
      this.isDisabled = true;

      axios.post("/authoring/courses/create", this.course).then((res) => {
        this.submitted = false;
        this.isDisabled = false;
        if (res.data.status === "success") {
          this.$v.$reset();
          this.course = {};
          this.$swal("Done!", res.data.message, "success");
          this.$router.push("/courses");
        } else {
          this.$swal("Nope!", res.data.message, "error");
        }
      });
    },
  },
};
</script>
