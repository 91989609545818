<template>
  <div class="CourseDetails">
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Course Details</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/courses">Courses</router-link>
              </li>
              <li class="breadcrumb-item active">Details</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-light">
              <div class="card-body">
                <!--  -->
                <!-- <p>
                  <router-link :to="'/courses/edit/' + course.uuid" class="btn btn-sm btn-outline-primary" :title="'Edit ' + course.name + ' Details'">Edit</router-link>&nbsp;
                  <router-link :to="'/lessons/' + course.uuid" class="btn btn-sm btn-outline-danger" :title="'Lessons ' + course.name + ' Details'">Lessons</router-link>&nbsp;
                  <button v-on:click="deleteCourse(course.uuid)" class="btn btn-sm btn-outline-default" :title="'Delete ' + course.name">
                    <i class="icon icon-trash-alt"></i>
                  </button>
                </p> -->
                <!--  -->
                <img :src="course.image" style="width: 800px" />
                <table class="table">
                  <tr>
                    <th>Name</th>
                    <td>{{ course.name }}</td>
                  </tr>
                  <tr>
                    <th>Introduction</th>
                    <td v-html="course.introduction"></td>
                  </tr>

                  <tr>
                    <th>Prerequisite</th>
                    <td v-html="course.prerequisite"></td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td v-html="course.description"></td>
                  </tr>
                </table>
              </div>

              <!-- <div class="card-footer">
                <router-link :to="'/courses/edit/' + course.uuid" class="btn btn-sm btn-outline-primary" :title="'Edit ' + course.name + ' Details'">Edit</router-link>&nbsp;
                <router-link :to="'/lessons/' + course.uuid" class="btn btn-sm btn-outline-danger" :title="'Lessons ' + course.name + ' Details'">Lessons</router-link>&nbsp;
                <button v-on:click="deleteCourse(course.uuid)" class="btn btn-sm btn-outline-default" :title="'Delete ' + course.name">
                  <i class="icon icon-trash-alt"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CourseDetails",
  data() {
    return {
      course: {},
    };
  },

  beforeMount() {
    axios
      .get("/authoring/courses/" + this.$route.params.uuid)
      .then((res) => {
        this.course = res.data.course;
      })
      .catch((error) => {});
  },
  computed: {},
  methods: {
    deleteCourse(uuid) {
      axios.post("/authoring/courses/delete/" + uuid).then((res) => {
        if (res.data.status === "success") {
          this.$swal("Done!", res.data.message, "success");
          this.$router.push("/courses");
        } else {
          this.$swal("Done!", res.data.message, "error");
        }
      });
    },
  },
};
</script>
