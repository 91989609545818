<template>
  <div class="container">
    <div class="jumbotron">
      <h1 class="display-4">Welcome!</h1>
      <p class="lead">This is content development and authroing tool.</p>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Course content authoring</h5>
            <p class="card-text">Upload video, audio, document file.</p>
            <router-link to="courses" class="btn btn-success">View courses</router-link>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Test question authoring</h5>
            <p class="card-text">Practice test, practice exams and live test questions.</p>
            <router-link to="tests" class="btn btn-success">View tests</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      courses: [],
    };
  },

  beforeMount() {},
};
</script>
